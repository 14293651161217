// .storybook/YourTheme.js

import { create } from '@storybook/theming/create';

export default create({
  base: 'light',
  brandTitle: 'Houdini',
  brandUrl: 'https://gethoudini.ai',
  brandImage:
    'https://storage.googleapis.com/gethoudini/assets/logo%2Bhoudini.png',
  brandTarget: '_blank',
});
